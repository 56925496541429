<!--客户管理-检测点管理-->
<template>
  <section>
    <!-- 工具条 -->
    <el-col :span="24" class="toolbar" style="padding-bottom: 0px">
      <el-form :inline="true" :model="filter" size="mini">
        <el-form-item class="locale-tool">
          <el-input
            v-model="filter.val"
            placeholder="关键字搜索"
            @keyup.native.enter="getData"
          >
            <el-select
              v-model="filter.field"
              slot="prepend"
              placeholder="请选择"
            >
              <el-option label="监测点" value="Name"></el-option>
              <el-option label="地址" value="Addr"></el-option>
              <el-option label="备注" value="Remark"></el-option>
            </el-select>
            <el-button
              slot="append"
              icon="el-icon-refresh"
              @click="handleRefresh"
            ></el-button>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-cascader
            placeholder="所属单位"
            @change="getData"
            v-model="filter.owner"
            :options="customerTree"
            :props="customerProps"
            clearable
            filterable
          />
        </el-form-item>
        <el-form-item>
          <el-cascader
            placeholder="区域"
            @change="getData"
            v-model="filter.area"
            :options="areaTree"
            :props="selAreaProps"
            clearable
            filterable
          />
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="filter.dateRange"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00','23:59:59']"
            @change="getData">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-select
            style="width: 200px;"
            placeholder="监测点状态"
            @change="getData"
            v-model="filter.statusOfRecord"
            clearable
            filterable
          >
            <el-option value="NORMAL" label="正常"/>
            <el-option value="OFFLINE" label="下线"/>
            <el-option value="REST" label="歇业"/>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            style="width: 200px;"
            placeholder="监测点类型"
            @change="getData"
            v-model="filter.localeType"
            clearable
            filterable
          >
            <el-option :value="0" label="全部"/>
            <el-option :value="1" label="监测类"/>
            <el-option :value="2" label="非监测类"/>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-cascader
            placeholder="经营类型"
            v-model="filter.managementTypes"
            :options="managementTypes"
            :props="{ checkStrictly: true }"
            @change="getData"
            clearable></el-cascader>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="openExportSizeInput">导出</el-button>
          <el-button type="primary" @click="handleAdd">新增</el-button>
          <el-button type="primary" @click="handleImport">导入非监测点</el-button>
        </el-form-item>
      </el-form>
    </el-col>
    <!-- 列表 -->
    <el-col :span="24">
      <el-table
        :data="datalist.content"
        size="mini"
        border
        highlight-current-row
        v-loading="loading"
        @selection-change="selsChange"
        :max-height="clientHeight"
        style="width: 100%"
        :key="itemKey"
      >
        <el-table-column type="selection"/>
        <el-table-column type="index" label="#" align="center" width="55"/>
        <el-table-column
          prop="Name"
          label="监测点"
          width="140"
          show-overflow-tooltip
          header-align="center"
        >
          <template slot-scope="scope">
            <el-popover
              trigger="hover"
              placement="top"
              title="维保人信息"
              :close-delay="120"
              @show="getMaintainer(scope.row.AreaId)"
            >
              <div
                v-if="!maintainer || maintainer.length === 0"
                style="text-align: center; font-weight: bold"
              >
                暂无信息
              </div>
              <template v-else>
                <div v-for="(m, i) in maintainer" :key="i">
                  <b>单位名称: </b>{{ m.Org }}<b> 联系电话: </b>{{ m.Mobile }}
                </div>
              </template>
              <div slot="reference">
                <el-tag size="mini">{{ scope.row.Name }}</el-tag>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          prop="owner_name"
          width="160"
          label="所属单位"
          show-overflow-tooltip
          header-align="center"
        />
        <el-table-column
          prop="CreateAt"
          width="160"
          label="创建时间"
          :formatter="dateFormat"
          show-overflow-tooltip
          header-align="center"
          align="center"
        />
         <el-table-column
          prop="type"
          label="监测类型"
          align="center"
          width="120"
          fixed="right"
          header-align="center"
        >
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.type == 1">监测类</el-tag>
            <el-tag type="info" v-else>非监测类</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="Cuisine"
          :formatter="cuisineFormatter"
          label="菜系"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="FanSpeed"
          label="风速"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="FanQuantity"
          label="风量"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="PipeArea"
          label="管道截面面积"
          width="96"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="StoveNum"
          label="灶头数量"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="OfflineJudge"
          label="异常判定[小时]"
          width="106"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="Samplings"
          label="抽样次数"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="EmissionsSill"
          label="超标阈值"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="LinkStatus"
          :formatter="linkFormatter"
          label="是否联动"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="StoveArea"
          label="集气灶面积"
          width="96"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="ExhaustTime"
          label="日均排烟时间"
          width="106"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="Remark"
          label="备注"
          width="120"
          show-overflow-tooltip
          header-align="center"
        />
        <el-table-column
          prop="Addr"
          label="详细地址"
          width="240"
          show-overflow-tooltip
          header-align="center"
        />
        <el-table-column
          prop="area_name"
          show-overflow-tooltip
          label="所属地区"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="Lng"
          label="经度"
          width="96"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="Lat"
          label="纬度"
          width="96"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="StatusOfRecord"
          label="状态"
          align="center"
          width="120"
          fixed="right"
          header-align="center"
        >
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.StatusOfRecord === 'NORMAL'">正常</el-tag>
            <el-tag type="error" v-else-if="scope.row.StatusOfRecord ==='OFFLINE'">下线</el-tag>
            <el-tag type="info" v-else>歇业</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          align="center"
          width="120"
          fixed="right"
          header-align="center"
        >
          <template slot-scope="scope">
            <i
              title="现场照片"
              @click="handleView(scope.$index, scope.row)"
              class="opt el-icon-view"
            />

            <i
              title="设备绑定记录"
              @click="handleRecord(scope.row)"
              class="opt el-icon-time"
            />
            <i
              title="编辑"
              @click="handleEdit(scope.$index, scope.row)"
              class="opt el-icon-edit"
            />
            <i
              title="删除"
              @click="handleDel(scope.$index, scope.row)"
              class="opt el-icon-delete"
            />
            <i
              title="状态变更记录"
              @click="handleStatusChange(scope.row)"
              style="color: #6badfb"
              class="opt el-icon-document"
            />
          </template>
        </el-table-column>
      </el-table>
    </el-col>

    <!-- 工具条 -->
    <el-col :span="24" class="toolbar">
      <el-button
        type="danger"
        size="mini"
        @click="bulkRemove"
        :disabled="sels.length === 0"
      >批量删除
      </el-button
      >
      <el-pagination
        small
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="filter.page"
        :page-sizes="filter.pageSizes"
        layout="total, sizes, prev, pager, next, jumper"
        :total="datalist.total"
        style="display: inline-block; margin-left: 15px"
      >
      </el-pagination>
    </el-col>

    <el-dialog :visible.sync="showImg">
      <template v-if="fileList && fileList.length">
        <el-image
          class="view-img"
          v-for="(item, i) in fileList"
          :preview-src-list="fileList"
          :key="i"
          :src="item">
          <div slot="error" class="image-slot">
            暂无图片
          </div>
        </el-image>
      </template>
      <div v-else>暂无图片</div>
    </el-dialog>
    <!-- 设备绑定记录 -->
    <el-dialog title="设备绑定记录" :visible.sync="recordVisible" width="800px">
      <el-table
        :data="recordlist.content"
        size="mini"
        border
        highlight-current-row
        :max-height="clientHeight - 40"
        style="width: 100%"
      >
        <el-table-column
          prop="DeviceMn"
          label="设备编号"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="BeginAt"
          :formatter="dateFormat"
          label="绑定时间"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="EndAt"
          :formatter="dateFormat"
          label="移机时间"
          align="center"
          header-align="center"
        />
      </el-table>
      <div>
        <el-pagination
          small
          background
          @current-change="handleRecordChange"
          :current-page.sync="filterRecord.page"
          layout="total, prev, pager, next, jumper"
          :total="recordlist.total"
          style="display: inline-block; margin-left: 15px"
        >
        </el-pagination>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click.native="recordVisible = false"
        >关闭
        </el-button
        >
      </div>
    </el-dialog>
    <!-- 生成过的一点一档记录 -->
    <el-dialog
      title="一点一档记录"
      :visible.sync="pdfRecordVisible"
      width="800px"
    >
      <el-form :inline="true" size="mini">
        <el-form-item>
          <el-button type="primary" @click="addPdf">新增一点一档</el-button>
        </el-form-item>
      </el-form>
      <el-table
        :data="pdfList"
        size="mini"
        border
        highlight-current-row
        :max-height="clientHeight - 40"
        style="width: 100%"
      >
        <el-table-column
          prop="BeginDate"
          label="开始时间"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="EndDate"
          label="结束时间"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="CreatedAt"
          label="生成时间"
          align="center"
          header-align="center"
        />
        <el-table-column label="操作" align="center" header-align="center">
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="handleLook(scope.row)"
            >查看
            </el-button
            >
            <!-- <i
              title="删除"
              @click="handleDelRecord(scope.row)"
              class="opt el-icon-delete-solid"
            /> -->
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click.native="pdfRecordVisible = false"
        >关闭
        </el-button
        >
      </div>
    </el-dialog>
    <!-- 一点一档新增选择时间 -->
    <el-dialog :visible.sync="pdfDateShow">
      <el-form
        :model="pdfData"
        label-width="120px"
        :rules="formRule"
        ref="form"
        size="mini"
      >
        <el-form-item label="选择时间段">
          <el-date-picker
            type="daterange"
            v-model="pdfData.time"
            value-format="timestamp"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click.native="pdfDateShow = false"
        >取消
        </el-button
        >
        <el-button type="primary" size="mini" @click.native="handleCreatePdf"
        >确定
        </el-button
        >
      </div>
    </el-dialog>

    <locale-edit-dialog
      :form-data="formData"
      :visible="dlg.visible"
      :title="dlg.title"
      :state="dlg.state"
      :is-view="isView"
      @handleClose="handleClose"/>
    <!--状态变更记录弹窗-->
    <status-change-dialog
      ref="statusChange"
      :visible.sync="statusChangeVisible"
      :locale-id="localeId"
      @handleClose="handleStatusClose"
      @handleStatusAdd="handleStatusToAdd"
      @handleStatusView="handleStatusToView"
    />
    <!--状态变更记录弹窗查看功能-->
    <offline-view-dialog
      :id="id"
      :visible.sync="statusViewVisible"
      :mode="mode"
      @handleClose="statusViewVisible = false"
    />
    <!--状态变更记录弹窗新增功能-->
    <offline-apply-dialog
      ref="offlineApply"
      :visible.sync="statusAddVisible"
      :locale-id="id"
      @handleClose="handleApplyClose"
    />

    <locale-batch-import-dialog
      :visible="batchImportVisible"
      @handleClose="handleImportClose"
    />

  </section>
</template>

<script>
import {mapState} from 'vuex'
import {dateFormater, base64ToBlob, ownerJoin} from '@/util/index'
import notificationDialog from '@/component/NotificationDialog'
import LocaleBatchImportDialog from "@/component/LocaleBatchImportDialog.vue";
import {
  getSinglePname,
  getPname,
  getSPname,
  loadData,
  getUserInfo,
  join,
  toIntArray,
  ownerFilter,
} from '@/util/index'
import conf from '@/config'
import LocaleEditDialog from "@/component/LocaleEditDialog.vue";
import StatusChangeDialog from "@/component/locale/StatusChangeDialog.vue";
import OfflineViewDialog from "@/component/offlineManagement/OfflineViewDialog.vue";
import OfflineApplyDialog from "@/component/locale/OfflineApplyDialog.vue";

export default {
  name: "Locale",
  components: {OfflineApplyDialog, LocaleEditDialog, StatusChangeDialog, notificationDialog, OfflineViewDialog, LocaleBatchImportDialog},
  data() {
    return {
      changeState: false,
      index: 0, // 记录更新index
      sels: [], // 列表选中列
      loading: false,
      datalist: {},
      userInfo: {},
      cuisine: [],
      managementList: [],
      managementTypes: [],
      exportList: [],
      showImg: false,
      maintainer: [],
      fileList: [],
      dlg: {
        visible: false,
        state: null,
        title: ''
      },
      filter: {
        statusOfRecord: '',
        localeType: '',
        val: null,
        owner: null,
        area: null,
        field: 'Name',
        dateRange: null,
        managementTypes: '',
        page: 1,
        size: 20,
        pageSizes: [20, 30, 50]
      },
      filterRecord: {
        StartAt: 0,
        Size: 20,
        page: 1,
        size: 20
      },
      localeId: '',
      baseInfo: {},
      recordVisible: false,
      pdfDateShow: false,
      pdfRecordVisible: false,
      batchImportVisible: false,
      pdfList: [],
      pdfData: {},
      isShowNotificationDialog: false, // 是否显示一点一挡
      isShowNotificationDialogData: {
        markLine: {
          symbol: 'none',
          itemStyle: {
            normal: {
              lineStyle: {
                type: 'solid'
                // color: "red",
              },
              label: {
                // show: false,
                position: 'start',
                formatter: '{c} '
              }
            }
          },
          data: []
        },
        dataOption: {
          columns: ['采集时间', '油烟折算浓度'],
          rows: []
        }
      }, // 一点一挡信息
      recordlist: {},
      formData: {
        voc_factory_model_id: ''
      },
      formRule: {
        Name: [{required: true, message: '名称不可为空', trigger: 'blur'}],
        Lng: [{required: true, message: '经度不可为空', trigger: 'blur'}],
        Lat: [{required: true, message: '纬度不可为空', trigger: 'blur'}]
      },
      DeviceNum: null,
      itemKey: 1,
      factoryList: [],
      factoryModelList: [],
      isView: false, //是否查看,
      statusChangeVisible: false,
      statusAddVisible: false,
      statusViewVisible: false,
      id: null,
      mode: 'view',
    }
  },
  computed: {
    ...mapState(['areaTree', 'clientHeight', 'locale', 'customerTree']),
    ...mapState({
      props: (state) => Object.assign({}, state.props, {label: 'Org'}),
      customerProps: (state) =>
        Object.assign({}, state.props, {label: 'Org', checkStrictly: true}),
      selAreaProps: (state) =>
        Object.assign({}, state.props, {checkStrictly: true}),
      areaProps: (state) => Object.assign({}, state.props)
    })
  },
  mounted() {
    try {
      const user = getUserInfo()
      this.userInfo = user.Info
    } catch (e) {
      this.$message.error(e)
    }
    this.$get('admin/listAllCuisine').then((res) => {
      this.cuisine = res
    })
    this.$get('admin/management').then((res) => {
      var data = []
      var keys = []
      this.managementList = res.list
      for (var i = 0; i < res.list.length; i++) {
        var index = keys.indexOf(res.list[i].type)
        if (index == -1) {
          keys.push(res.list[i].type)
          data.push({
            value: res.list[i].type,
            label: res.list[i].type == 1?"经营性":"非经营性",
            children:[{
              value: res.list[i].id,
              label: res.list[i].name,
            }]
          })
        } else {
          data[index].children.push({
            value: res.list[i].id,
            label: res.list[i].name,
          })
        }
      }
      this.managementTypes = data
    })
    // 更新所属单位（不排除未来需要更新更多数据
    if (!this.customerTree) {
      this.$get('admin/listBasicDataOfCustomer').then(res => {
        this.$store.dispatch('setCustomer', res.Customer)
      })
    }
    if (this.areaTree.length === 0) {
      this.$get('admin/listBasicDataOfArea').then(res => {
        this.$store.dispatch('setArea', res.Area)
      })
    }
    this.getData()
  },
  methods: {
    getData() {
      this.loading = true
      const param = {
        StartAt: (this.filter.page - 1) * this.filter.size,
        Size: this.filter.size,
        Param: {}
      }
      // 所属单位搜索
      if (this.filter.owner && this.filter.owner.length > 0) {
        const owner = `${ownerJoin(this.filter.owner, this.userInfo)}`
        param.Param['Owner'] = owner
      }
      //关键字搜索
      if (this.filter.field && this.filter.val) {
        param.Param[this.filter.field] = this.filter.val
      }
      //区域信息搜索
      if (this.filter.area && this.filter.area.length != 0) {
        param.Param['Area'] = join(this.filter.area)
      }
      //日期范围选择
      if (this.filter.dateRange) {
        param.Param["StartAt"] = this.filter.dateRange[0].getTime() / 1000
        param.Param["EndAt"] = this.filter.dateRange[1].getTime() / 1000
      }
      if (this.filter.statusOfRecord !== "") {
        param.Param.statusOfRecord = this.filter.statusOfRecord
      }
      if (this.filter.localeType > 0) {
        param.Param['LocaleType'] = this.filter.localeType
      }
      if (this.filter.managementTypes.length == 1) {
        param.Param['Management'] = this.filter.managementTypes[0]
      }
      if (this.filter.managementTypes.length == 2) {
        param.Param['ManagementTypes'] = this.filter.managementTypes[1]
      }
    
      this.$post("admin/listLocale", param).then(res => {
        this.datalist.content = res.content
        this.datalist.total = res.total
        this.itemKey = Math.random()
        this.loading = false
      })
    },
    openExportSizeInput() {
      this.$prompt(`请输入导出的数据量（总量：${this.datalist.total} 条）`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^[0-9]*$/,
        inputErrorMessage: '只能填入数字'
      }).then(({value}) => {
        this.handleExport(parseInt(value))
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消'
        })
      })
    },
    handleExport(num) {
      const param = {
        StartAt: (this.filter.page - 1) * this.filter.size,
        Size: num,
        Param: {IsDownload: true}
      }
      // 所属单位搜索
      if (this.filter.owner && this.filter.owner.length > 0) {
        const owner = `${ownerJoin(this.filter.owner, this.userInfo)}`
        param.Param['Owner'] = owner
      }
      //关键字搜索
      if (this.filter.field && this.filter.val) {
        param.Param[this.filter.field] = this.filter.val
      }
      //区域信息搜索
      if (this.filter.area && this.filter.area.length != 0) {
        param.Param['Area'] = join(this.filter.area)
      }
      //日期范围选择
      if (this.filter.dateRange) {
        param.Param["StartAt"] = this.filter.dateRange[0].getTime() / 1000
        param.Param["EndAt"] = this.filter.dateRange[1].getTime() / 1000
      }
      if (this.filter.statusOfRecord !== "") {
        param.Param.statusOfRecord = this.filter.statusOfRecord
      }
      if (this.filter.localeType > 0) {
        param.Param['LocaleType'] = this.filter.localeType
      }
      if (this.filter.managementTypes.length == 2) {
        param.Param['ManagementTypes'] = this.filter.managementTypes[1]
      }
      this.$post("admin/listLocale", param).then(res => {
        let url = res.url
        const link = document.createElement('a')
        link.setAttribute('href', url)
        link.click()
      })
    },
    // 新增一点一档
    addPdf() {
      this.pdfData = {}
      this.getLast7Days()
      this.pdfDateShow = true
      this.pdfRecordVisible = false
    },
    // 一点一档--历史记录
    // handlePdfRecordList(row) {
    //   this.localeId = row.Id
    //   // this.baseInfo = {
    //   //   LocaleId: row.Id,
    //   //   CustomerName: row.Name,
    //   //   Addr: row.Addr,
    //   //   CustomerBlNo: row.CustomerBlNo,
    //   //   CustomerContact: row.Contact,
    //   //   CustomerMobile: row.Mobile
    //   // }
    //   this.pdfRecordVisible = true
    //   this.pdfList = []
    //   this.$post('admin/queryLocaleArchive', {LocaleId: this.localeId})
    //     .then((res) => {
    //       this.pdfList = res
    //     })
    //     .catch(() => {
    //     })
    // },
    handleCreatePdf() {
      this.pdfDateShow = false
      const par = {
        LocaleId: this.localeId,
        AcquitAtBegin: this.pdfData.time[0] / 1000,
        AcquitAtEnd: this.pdfData.time[1] / 1000
      }
      const list = []
      let customForm = {}
      this.$post('admin/listDataDetectorDailySumDaysByLocaleId', par)
        .then((res) => {
          list.push(res)
          customForm = {...res.customer}
        })
        .then((response) => {
          // const tpl = { mn: '010000A8900016F018010029' };
          const tpl = {localeId: this.localeId}
          tpl['AcquitAtBegin'] = this.pdfData.time[0] / 1000
          tpl['AcquitAtEnd'] = this.pdfData.time[1] / 1000
          this.$post('admin/queryDataDetectorDaily', tpl).then((res) => {
            this.isShowNotificationDialogData = Object.assign(
              {},
              this.isShowNotificationDialogData,
              customForm
            )
            this.isShowNotificationDialogData.LocaleId = this.localeId
            this.isShowNotificationDialogData.beginDate = dateFormater(
              tpl['AcquitAtBegin'],
              false
            )
            this.isShowNotificationDialogData.endDate = dateFormater(
              tpl['AcquitAtEnd'],
              false
            )
            let data
            this.isShowNotificationDialogData.dataOption.rows = []
            for (var i = tpl['AcquitAtBegin']; i <= tpl['AcquitAtEnd']; i += 86400) {
              data = res.find((e) => e.AcquitAt === i)
              this.isShowNotificationDialogData.dataOption.rows.push({
                采集时间: dateFormater(i, false),
                油烟折算浓度: data ? data.CEmissions : 0,
                颗粒物折算浓度: data ? data.CGranule : 0,
                非甲烷总烃折算浓度: data ? data.CHydrocarbon : 0
              })
            }
            this.isShowNotificationDialog = true
            this.isShowNotificationDialogData.tableData = list
            this.isShowNotificationDialogData.markLine.data.push({
              lineStyle: {
                color: '#00A1FF'
              },
              yAxis: res[0].LocaleEmissionsSill || 0
            }, {
              lineStyle: {
                color: '#F5B049'
              },
              yAxis: res[0].LocaleGranuleSill || 0
            }, {
              lineStyle: {
                color: '#009999'
              },
              yAxis: res[0].LocaleHydrocarbonSill || 0
            })
          })
        })
        .catch(() => {
        })
    },
    handleDelRecord(row) {
      this.$post('admin/removeLocaleArchive', {Id: row.Id})
        .then((res) => {
          this.$post('admin/queryLocaleArchive', {LocaleId: this.localeId})
            .then((res) => {
              this.pdfList = res
            })
            .catch(() => {
            })
        })
        .catch(() => {
        })
    },
    getLast7Days() {
      this.pdfData.time = []
      const now = new Date()
      now.setDate(now.getDate() - 1)
      now.setHours(0, 0, 0, 0)
      this.pdfData.time[1] = now.getTime()
      now.setDate(now.getDate() - 30)
      this.pdfData.time[0] = now.getTime()
    },
    // 查看历史一点一档
    handleLook(row) {
      this.download(row.LocaleId, row.Archive)
    },
    // 下载图片
    download(name, imgData) {
      this.downloadFile(name, imgData)
    },
    // 下载
    downloadFile(fileName, content) {
      const aLink = document.createElement('a')
      const blob = base64ToBlob(content) // new Blob([content]);
      const evt = document.createEvent('HTMLEvents')
      evt.initEvent('click', true, true) // initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
      aLink.download = fileName
      aLink.href = URL.createObjectURL(blob)
      aLink.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window
        })
      ) // 兼容火狐
    },
    hasChange() {
      this.changeState = true
    },
    getPname(r, c) {
      return getPname(this.customerTree, r[c.property], 'Org').join('/')
    },
    getArea(r, c) {
      return getSinglePname(this.areaTree, r[c.property])
    },

    upload() {
      return conf.uploadApi + '/uploadToOss'
    },
    handleAvatarSuccess(res, file) {
      this.fileList.push(res.Data)
    },

    getMaintainer(id) {
      this.$get('admin/getAreaMaintainer', {areaId: id})
        .then((res) => {
          this.maintainer = res
        })
        .catch(() => {
        })
    },

    handleRecord(row) {
      this.localeId = row.Id
      this.getRecordList()
    },
    getRecordList() {
      const filterRecord = JSON.parse(JSON.stringify(this.filterRecord))
      filterRecord.StartAt = filterRecord.size * (filterRecord.page - 1)
      filterRecord.Size = filterRecord.size
      filterRecord['Param'] = {Locale_Id: this.localeId}
      this.$post('admin/listLocaleDeviceRecord', filterRecord)
        .then((res) => {
          this.recordlist = res
          this.recordVisible = true
        })
        .catch(() => {
        })
    },
    handleRecordChange(page) {
      this.filterRecord.page = page
      this.getRecordList()
    },
    dateFormat(r, c) {
      return dateFormater(r[c.property], true)
    },
    handleOwnerChange(v) {
      this.changeState = true
    },
    handleView(index, row) {
      this.fileList = []
      const par = {locale_id: row.Id}
      this.$get('admin/locale/info', par).then((res) => {
        this.fileList = res.locale.locale_pics
        this.showImg = true
      })
    },
    handleBeforeRemove(file) {
      return this.$confirm('确认删除吗？').then(() => {
        const reg = /res\/(.*)\.[a-zA-Z]+$/
        this.$get('admin/delLocaleAt', {fid: file.url.match(reg)[1]})
      })
    },
    removeImg(idx) {
      this.changeState = true
      this.fileList.splice(idx, 1)
    },
    transformCuisine(k) {
      for (let i = 0, len = this.cuisine.length; i < len; i++) {
        if (this.cuisine[i].Id === k) {
          return this.cuisine[i].Name
        }
      }
      return ''
    },
    cuisineFormatter(r, c) {
      return this.transformCuisine(r[c.property])
    },
    linkFormatter(r, c, v) {
      return v === 1 ? '是' : '否'
    },
    handleRefresh(clear = true) {
      if (clear) {
        this.filter.page = 1
        this.filter.val = null
        this.filter.owner = null
        this.filter.area = null
      }
      loadData(
        this,
        (res) => {
          this.$store.dispatch('setLocale', res).then(() => {
            this.getData()
          })
        },
        3
      )
    },
    selsChange: function (sels) {
      this.sels = sels
    },
    // 改变页容量
    handleSizeChange(size) {
      this.filter.size = size
      this.getData()
    },
    // 翻页
    handleCurrentChange(page) {
      this.filter.page = page
      this.getData()
    },
    formInit() {
      this.formData = {
        voc_factory_model_id: ''
      }
    },
    handleAdd: function () {
      this.dlg.title = '新增'
      this.$post('admin/listFactory', {}).then(res => {
        this.factoryList = res.content
      })
      this.dlg.visible = true
      this.dlg.state = true
      this.fileList = []
      this.formInit()
      this.formData = {}
      this.$set(this.formData, 'status_of_record', 'OFFLINE')
      this.$set(this.formData, 'status_name', '下线')
      this.$set(this.formData, 'type', 1)
    },
    handleEdit: function (index, row) {
      this.dlg.title = '编辑'
      this.dlg.state = false
      this.index = index
      this.fileList = []
      this.formInit()
      const par = {locale_id: row.Id}
      let userInfo = getUserInfo().Info
      this.$get('admin/locale/info', par)
        .then((res) => {
          const data = res
          this.fileList = data.locale.locale_pics
          if (data.locale.type == 1) {
            this.formData = Object.assign({}, data.locale)
            this.formData.surpass_calc_method = data.locale.surpass_calc_method === 0 ? null : data.locale.surpass_calc_method
            this.$set(this.formData, 'voc_factory_model_id', data.factory.id)
            this.$set(this.formData, 'voc_factory_name', data.factory.factory_name)
            for(var i = 0; i < this.cuisine.length; i++) {
              if (this.formData.cuisine == this.cuisine[i].Id) {
                this.$set(this.formData, 'cuisine', [this.cuisine[i].ResourceName, this.cuisine[i].Id])
                break
              }
            }
            for(var i = 0; i < this.managementList.length; i++) {
              if (this.formData.management_types == this.managementList[i].id) {
                this.$set(this.formData, 'management_types', [this.managementList[i].type==1?"经营性":"非经营性", this.managementList[i].id])
                break
              }
            }
          } else {
            this.formData = {
              id: data.locale.id,
              owner: data.locale.owner,
              addr: data.locale.addr,
              name: data.locale.name,
              area_id: data.locale.area_id,
              stove_num: data.locale.stove_num,
              type: data.locale.type,
              scale: data.locale.scale,
              status_of_record: data.locale.status_of_record,
              filter_wash_day:data.locale.filter_wash_day,
              management_types: data.locale.management_types,
            }
          }
          for(var i = 0; i < this.managementList.length; i++) {
            if (this.formData.management_types == this.managementList[i].id) {
              this.$set(this.formData, 'management_types', [this.managementList[i].type==1?"经营性":"非经营性", this.managementList[i].id])
              break
            }
          }
          this.formData.owner = ownerFilter(this.formData.owner, userInfo)
          this.formData.area_id = data.locale.area_id === '' ? [] : toIntArray(data.locale.area_id)
          if (data.locale.status_of_record === "NORMAL") {
            this.$set(this.formData, 'status_name', '正常')
          } else if (data.locale.status_of_record === "OFFLINE") {
            this.$set(this.formData, 'status_name', '下线')
          } else if (data.locale.status_of_record === "REST") {
            this.$set(this.formData, 'status_name', '歇业')
          }
          this.dlg.visible = true
        })
        .catch(() => {
        })
    },
    // 批量删除
    bulkRemove: function () {
      const ids = this.sels.map((item) => item.Id).toString()
      this.$confirm('确认删除选中记录吗？', '提示', {
        type: 'warning'
      })
        .then(() => {
          this.$post('admin/bulkRemoveLocale', {ids: ids})
            .then((res) => {
              this.getData()
            })
            .catch(() => {
            })
        })
        .catch(() => {
        })
    },
    handleDel: function (index, row) {
      this.$confirm('确认删除该记录吗?', '提示', {
        type: 'warning'
      })
        .then(() => {
          const para = {id: row.Id}
          this.$get('admin/delLocale', para)
            .then(() => {
              this.getData()
            })
            .catch(() => {
            })
        })
        .catch(() => {
        })
    },
    handleClose() {
      this.dlg.visible = false
      this.isView = false
      this.getData()
    },
    handleStatusChange(row) {
      this.statusChangeVisible = true
      this.localeId = row.Id
    },
    handleStatusClose() {
      this.statusChangeVisible = false
    },
    handleStatusToAdd(id) {
      this.id = id
      this.statusAddVisible = true
    },
    handleStatusToView(id, mode) {
      this.id = id
      this.mode = mode
      this.statusViewVisible = true
    },
    handleApplyClose() {
      this.statusAddVisible = false
      this.$refs.statusChange.getData()
    },
    handleImportClose() {
      this.batchImportVisible = false
      this.getData()
    },
    handleImport() {
      this.batchImportVisible = true
    },
  }
}
</script>

<style lang="scss" scoped>


.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 78px;
  height: 78px;
  line-height: 78px;
  text-align: center;
}

.single-img > img {
  width: 160px;
  height: 160px;
}

.view-img {
  width: 178px;
  height: 178px;
  margin-right: 10px;
}

.remove-img {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
  color: #FFF;
  text-align: center;
  background: rgba(0, 0, 0, .5);
  transition: transform .3s;
  -webkit-transition: transform .3s;
}

.remove-img:hover {
  cursor: pointer;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
}


.locale-tool {
  .el-select--mini ::v-deep .el-input--suffix {
    width: 120px;
  }
}

.form-part {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
  border-color: #eeeeee;
}
</style>
